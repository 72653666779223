import React from 'react'
import { Link } from 'gatsby'
import { Card } from 'antd'

const NewsList = (props) => {
	return (
		<div className='news-list'>
			{props.news &&
				props.news.map((page, index) => {
					return (
						<div className='news-card' key={index}>
							<Link to={`/news/${page.slug}`} className='text-left'>
								<Card style={{ width: 300, marginTop: 16 }}>
									<Card.Meta title={page.title} description={page.content} />
								</Card>
							</Link>
						</div>
					)
				})}
		</div>
	)
}

export default NewsList
