import React from 'react'
import { Layout } from 'antd'

import MainLayout from '../../components/MainLayout'
import SEO from '../../components/seo'

import NewsList from '../../components/news/NewsList'

const News = () => {
	const news = require('../../data/news')
	return (
		<MainLayout>
			<SEO title='News' />

			<h1 className='distanced'>
				<span className='symbols'>-:-</span>
				<span>READ</span>
				<span className='symbols'>-:-</span>
			</h1>

			<Layout>
				<Layout.Content className='text-center'>{news && <NewsList news={news} />}</Layout.Content>
			</Layout>
		</MainLayout>
	)
}

export default News
